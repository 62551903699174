import { Button } from '@mui/material';
import React, { useState } from 'react';
import { FaFacebook, FaRegEnvelope, FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { FcGoogle } from "react-icons/fc";
import { MdLock } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Col, Container, Row } from 'reactstrap';
import logoTwo from '../../assets/imges/Logo.png';
import '../../style/businesLogin.scss';
const TrialForgot = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [type, setType] = useState('password');


  const toggleType = () => {
    if (type == 'password') {
      setType('text');
    }
    else {
      setType('password');

    }
  }



  const handleSubmit = (e) => {
    e.preventDefault();


  }



  return (
    <>
      <Container fluid className=' inter' >
        <Row className='min-h-screen flex flex-col justify-between' style={{ backgroundColor: '#ffffff' }}>

          <div className='flex justify-between items-center gap-2 p-2' >
            <img src={logoTwo} alt="w8" className=' ' width="150" />
            <Link to="/trial/login">
              <button type='button' className='px-3 p-2  border-[1.5px] rounded-lg text-black'>Login</button>
            </Link>
          </div>

          <Col lg={6} md={12} sm={12} xs={12} className='align-items-center justify-content-center d-flex  my-2 mx-auto' >
            <Container fluid >
              <Row className='justify-content-center'>
                <Col lg={9} md={9} sm={12} xs={12} >
                  <form className='login-form' onSubmit={handleSubmit}>
                    <div className='inter '>
                      <h4 className="font-24 fw-bold  text-black text-center">Forgot password?</h4>
                      <p className="font-14 text-gray-400 text-center">No worries Just enter your email and we'll send you login instructions</p>

                    </div>

                    <div>
                      <div className='my-2'>
                        <label className='text-sm mb-2'>Business email</label>
                        <input type="email" required className=" !placeholder-gray-400	text-sm p-2.5 rounded-3 border-[1.2px] border-gray-300 focus:outline-none w-full mb-2" placeholder="name@work-email.com" />
                      </div>
                    </div>
                   
                    <Link to="/trial/forgotPasswordWay">
                    <Button type='submit' className='btn-blueTwo rounded-3 inter !p-2.5 w-100 fw-semibold text-sm text-capitalize ' sx={{ fontFamily: 'Inter, sans-serif !important' }} >Send reset email</Button>
                    </Link>

                  </form>
                 
                 
                </Col>
              </Row>
            </Container>
          </Col>
          <p className="text-center  mt-4">
            @2024 Time Tech Pty Ltd,All Rights Reserved
            <br />
            <span className="font-16 font-bold cursor-pointer colorPrimary inter"
              onClick={() => navigate('/auth/signup')}> Privacy Policy</span>
          </p>
        </Row>
        <ToastContainer />
      </Container>
    </>
  );
}

export default TrialForgot;
