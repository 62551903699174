import React, { useState, useEffect } from 'react'
import Header from '../../components/Header'
import SideBar from '../../components/SideBar'
import DevicesTable from '../../components/DevicesTable'
import '../../style/admin/index.scss'
import { Input, Spinner } from "reactstrap";
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createBusinessDeviceIssue } from '../../redux/slices/userSlice';
import {
    Autocomplete,
    Button,
    Chip,
    FormControl,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import uploadicon from "../../assets/imges/upload.png";
import CloseIcon from "@mui/icons-material/Close";
import { MdDevices } from "react-icons/md";
import { PiWarningCircleFill } from "react-icons/pi";
import { FaPlus } from 'react-icons/fa'
import { Modal } from 'react-bootstrap'
import { GrFormClose } from "react-icons/gr";
import NewDevicesTable from '../../components/NewDevicesTable'


const NewDevice = () => {
    const dispatch = useDispatch();
    const { isLoading, getError, businessDevices } = useSelector((state) => state.user)
    const [reasion, setreasion] = useState();
    const [subject, setSubject] = useState();
    const [priority, setPriority] = useState();
    const [serialNumber, setSerialNumber] = useState();
    const [query, setQuery] = useState();
    const [logo, setLogo] = useState();
    console.log(logo, '============ logo')
    const [email, setEmail] = useState();
    const [deviceId, setDeviceId] = useState();

    const [showModal, setShowModal] = useState(false);

    // ========Drag Drop========
    const handleDrop = (e) => {
        e.preventDefault();

        const droppedFile = e.dataTransfer.files[0];
        if (droppedFile) {
            setLogo(droppedFile);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };
    const handleShowModal = (sno, deviceId) => {
        // alert(sno)
        setSerialNumber(sno);
        setDeviceId(deviceId);
        setShowModal(true)
    }

    useEffect(() => {
        if (getError && getError.length > 1) {
            toast.error(getError, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }, [getError])

    const handleIssueCreate = (e) => {
        e.preventDefault();
        if (serialNumber && deviceId && query && email && reasion && subject) {
            let data = new FormData();
            data.append('serial_number', serialNumber);
            data.append('image', logo);
            data.append('device_id', deviceId);
            data.append('email', email);
            data.append('query_str', query);
            data.append('reason', reasion);
            data.append('subject', subject);
            data.append('priority', priority);
            dispatch(createBusinessDeviceIssue(data, handleResponse))

        } else {
            toast.error('oops! Fields marked with * are required.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
    function handleResponse(data) {
        toast.success('Your issue has been reported. We are sorry for inconvenience. Your Issue Id is #' + data?.id, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        setreasion(null);
        setSubject(null);
        setSerialNumber(null);
        setQuery(null);
        setLogo(null);
        setEmail(null);
        setDeviceId(null);
        setShowModal(false);
    }
    return (
        <section className='business_admin_main'>
            <div className="container-fluid Device-main ">
                <div className="row order ">

                    {/* <!-- ===========SID-BAR======= --> */}
                    <SideBar />
                    {/* <!-- ========= content body ======= --> */}

                    <div className="w-lg-80 px-0 h-screen overflow-y-scroll">
                    {/* <!-- ===========HEADER========== --> */}
                    <Header PageTitle="Devices"/>
                    <div className="px-lg-4 px-md-3 px-2">

                        <div className="row inter mt-4">
                            {/* <div className="col-lg-6 col-md-6 col-12 ">
                                <h4 className="font-20">Devices</h4>
                            </div> */}

                        </div>
                        <NewDevicesTable handleShowModal={handleShowModal} />
                    </div>
                    </div>
                </div>
            </div>
            {/* Modal */}

            <Modal size='lg' show={showModal} className='modals_scroll business_admin_main' centered  >

                <Modal.Body style={{ maxHeight: '85vh', overflowY: 'auto' }}>
                    <div className=" px-2 pb-3 rounded-5">
                        <div className="d-flex flex-row justify-content-between">
                            <h4 className="font-25 ps-2 inter mt-3 w-100">
                                Report a Problem
                            </h4>
                            <span onClick={() => setShowModal(false)} className='mt-3 cursor-pointer'><GrFormClose size={30} /></span>
                        </div>
                        <form className='mt-3' onSubmit={handleIssueCreate}>
                            <div className="container-fluid">



                                <div className="row mt-1">
                                    <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-md-0 mt-3">
                                        <p className=" text-dark mb-1 text-start font-17 fw-medium ">
                                            Subject*
                                        </p>
                                        <input
                                            type="text" required
                                            className="form-control  input-field  font-17 p-3 rounded-5" placeholder="Write a subject"
                                            value={subject} onChange={(e) => setSubject(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12  serial-number">
                                        <p className=" text-dark mb-1 text-start font-17 fw-medium ">
                                            Reason*
                                        </p>
                                        <FormControl className="mui-select-field   w-100">
                                            <Select required
                                                value={reasion}
                                                className="rounded-5 w-100 text-start font-17"
                                                onChange={(e) =>
                                                    setreasion(e.target.value)
                                                }
                                                displayEmpty
                                                inputProps={{
                                                    "aria-label": "Without label",
                                                }}
                                            >
                                                <MenuItem value="">Select</MenuItem>
                                                <MenuItem value={'notresponding'}>Not Responding</MenuItem>
                                                <MenuItem value={'batterissue'}>Battery Issue</MenuItem>
                                                <MenuItem value={'designissue'}>Design Issue</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>


                                <div className="row mt-3">
                                    <div className="col-12">
                                        <p className=" text-dark mb-1 text-start font-17 fw-medium ">
                                            Serial Number*
                                        </p>
                                        <input
                                            type="text" required
                                            className="form-control  input-field  font-17 p-3 rounded-5" placeholder="Serial Number"
                                            value={serialNumber} readOnly
                                        />
                                    </div>

                                </div>
                                <div className="col-lg-6 col-md-6 col-12  serial-number">
                                    <p className=" text-dark mb-1 text-start font-17 fw-medium ">
                                        Priority*
                                    </p>
                                    <FormControl className="mui-select-field   w-100">
                                        <Select required
                                            value={priority}
                                            className="rounded-5 w-100 text-start font-17"
                                            onChange={(e) =>
                                                setPriority(e.target.value)
                                            }
                                            
                                            inputProps={{
                                                "aria-label": "Without label",
                                            }}
                                            placeholder='Select Priority'
                                        >
                                            
                                            <MenuItem value={'P1 Critical Outage'}>P1 Critical Outage</MenuItem>
                                            <MenuItem value={'P2 Degraded'}>P2 Degraded</MenuItem>
                                            <MenuItem value={'P3 General'}>P3 General</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-lg-9 col-md-6 col-12 " >
                                        <p className=" text-dark mb-1 text-start font-17 fw-medium ">
                                            Query*
                                        </p>
                                        <textarea required
                                            className="form-control h-100 rounded-5 input-field "
                                            id="textarea" value={query} onChange={(e) => setQuery(e.target.value)}
                                            rows={5}
                                        />
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-12 mt-lg-0 mt-md-0 mt-5" >
                                        <p className=" text-dark mb-1 text-start font-17 fw-medium ">
                                            Logo                                        </p>
                                        <input type="file" className='d-none' id='file' onChange={(e) => setLogo(e.target.files[0])}
                                            accept=".jpeg, .jpg, .png, .gif, .pdf, .psd, .ai, .doc, .docx, .ppt, .pptx"
                                        />
                                        <label htmlFor="file" className='inputgroup1 d-flex align-items-center justify-content-center  rounded-5 w-100 h-100 ' onDrop={handleDrop}
                                            onDragOver={handleDragOver}>
                                            <div className=" text-center">

                                                <img
                                                    src={uploadicon}
                                                    width={40}
                                                    alt="w8"
                                                />
                                                <p className="font-10 mb-0 fw-bold">Drag & drop files or <u className='blue'>Browse</u></p>
                                                <p className="text-dark-gray mb-0 lh-lg " style={{ fontSize: '6px' }}>
                                                    Supported formates: <br />
                                                    JPEG, PNG, GIF, MP4, PDF, PSD, AI, Word, PPT
                                                </p>
                                            </div>
                                        </label>

                                    </div>

                                </div>
                                {logo &&
                                    <div className="row justify-content-end ">
                                        <div className="col-lg-3 col-md-3 col-12">
                                            <p className="mt-5 text-dark mb-1 text-start font-14 fw-medium ">
                                                File name:
                                                <li className='font-12 fw-normal'>

                                                    {logo.name}
                                                </li>
                                            </p>

                                        </div>

                                    </div>
                                }
                                <div className="row mt-3">
                                    <div className="col-12">
                                        <div className="text-start">
                                            <p className="mt-4 text-dark mb-1 text-start font-17 fw-medium ">
                                                Email*
                                            </p>
                                            <input required
                                                type="email" value={email} onChange={(e) => setEmail(e.target.value)}
                                                className="form-control input-field  font-17 p-3 rounded-5"

                                            />
                                        </div>
                                    </div>
                                </div>



                            </div>
                            <div className="container-fluid">

                                <div className="row mt-4 justify-content-between ">
                                    <div className="col-lg-4 col-md-4 col-6 text-center">
                                        <Button
                                            className="btn-white-borderTwo rounded-5 w-100  p-3 fw-bold font-20 text-capitalize"
                                            onClick={() => setShowModal(false)}                                >
                                            Cancel
                                        </Button>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-6 text-center">
                                        <Button type='submit'
                                            className="btn-blueTwo rounded-5 w-100 p-3 fw-bold font-20 text-capitalize"
                                        >
                                            {isLoading ? <Spinner /> : 'Create'}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>

            </Modal>
            <style>
                {`
                    
                    .modal-content{
                        border-radius:20px;
                    }
                    `}
            </style>
            <ToastContainer />

        </section>
    )
}

export default NewDevice
