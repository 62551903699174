import React, { useEffect, useState } from "react";
// import Checkbox from "@mui/material/Checkbox";
import {
    // Autocomplete,
    Button,
    // Chip,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    // TextField,
    ThemeProvider,
    createTheme
} from "@mui/material";
// import TableDownload from "../assets/imges/downloadDeviceTable.png";
// import { HiSearch } from "react-icons/hi";
// import karlzzz from "../assets/imges/karlzzzz.png";
import link from "../assets/imges/DeviceTableLink.png";
import Setting from "../assets/imges/DeviceTableSetting.png";
import Toggle from "../assets/imges/DeviceTableToggle.png";
import img from "../assets/imges/quickbooks.png";
// import DropDots from "../assets/imges/DeviceTableDropdown.png";
// import DropDownload from "../assets/imges/DeviceTableDownloadIMG2.png";
// import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
// import CloseIcon from "@mui/icons-material/Close";
// import { MdDevices } from "react-icons/md";
import { Modal } from "react-bootstrap";
import { GoTriangleDown } from "react-icons/go";
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Input, Spinner } from "reactstrap";
import { getBusinessDevices, linkDevice, updateDevice } from "../redux/slices/userSlice";
// const options = [
//     { label: "Option 1", value: "option1" },
//     { label: "Option 2", value: "option2" },
//     { label: "Option 3", value: "option3" },

// ];
const theme = createTheme({
    components: {
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: 'rgba(200, 195, 201, 1)',

                },
            },
        },
    },
});
export default function NewDevicesTable({ handleShowModal }) {
    const dispatch = useDispatch();
    const { isLoading, getError, businessDevices } = useSelector((state) => state.user)
    // const [checked, setChecked] = useState([true, false]);

    // const [select1, setSelect1] = useState("");
    // const [select2, setSelect2] = useState("");
    // const [select3, setSelect3] = useState("");
    // const [select4, setSelect4] = useState("");
    // const [select5, setSelect5] = useState('');
    const [status, setStatus] = React.useState(false);
    const [statusRadio, setStatusRadio] = React.useState('Active');


    useEffect(() => {
        if (getError && getError.length > 1) {
            toast.error(getError, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }, [getError])
    useEffect(() => {
        dispatch(getBusinessDevices());
    }, []);
    // const handleChange1 = (event) => {
    //     setChecked([event.target.checked, event.target.checked]);
    // };

    // const handleChange2 = (event) => {
    //     setChecked([event.target.checked, checked[1]]);
    // };
    function handleSetDefaultDevice(deviceId) {
        var data = {
            device_id: deviceId,
            is_default: 1,
        }
        dispatch(updateDevice(data, handleResponse))

    }

    function handleResponse(data) {
        if (data?.status) {
            toast.success('Device Set default Successfully', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            dispatch(getBusinessDevices());

        }

    }
    // ========= location Modal
    const [locationModal, setLocationModal] = useState(false);
    const [selectedValues, setSelectedValues] = useState([]);
    const [selectedDeviceId, setSelectedDeviceId] = useState(null);

    const handleClose = () => {
        setLocationModal(false);
        setSelectedValues([]);
        setSelectedDeviceId(null);

    };
    const handlelocationModal = (id) => {
        setLocationModal(true)
        setSelectedDeviceId(id);
        console.log(id)
    };
    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            if(selectedValues.length >= 2 ){
                toast.error('You Can only select 2 locations at max', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                return false;
            }
            setSelectedValues((prev) => [...prev, value]);
        } else {
            alert('should uncheck now!')
            setSelectedValues((prev) => prev.filter((val) => val !== value));
        }
    };
    function handleLinkDevices(){
        if(selectedValues.length < 1 && !selectedDeviceId){
            alert('request failed')
            return false;
        }else{
            var data = {
                deviceId: selectedDeviceId,
                locationIds: selectedValues
            }
            dispatch(linkDevice(data))
            setLocationModal(false);
        }
    }
    return (
        <>
            <ThemeProvider theme={theme}>
                <div className="container-fluid mt-3 px-0">

                    <div className='row justify-content-between new-select-option g-0'>
                        <div className="col-3 position-relative">

                            <div className=' stats_select rounded-5  d-flex align-items-center white-border '
                                onClick={() => setStatus(!status)}>
                                <p className='mb-0 me-1 text-dark-gray fw-medium '>Status:</p>
                                <div className='d-flex align-items-center justify-content-between w-100'>

                                    {statusRadio}
                                    <GoTriangleDown />
                                </div>
                            </div>
                            {
                                status &&
                                <div className='position-absolute bg-white p-2 px-3 shadow w-100 top-100 start-0 rounded-4 z-1'>
                                    <FormControl>

                                        <RadioGroup

                                            // defaultValue="All-time"
                                            onChange={(e) => {
                                                setStatusRadio(e.target.value);
                                                setStatus(false)
                                            }
                                            }
                                            value={statusRadio}
                                            name="radio-buttons-status"
                                        >
                                            <FormControlLabel value="Active" control={<Radio
                                                sx={{
                                                    color: '#1D59F9',
                                                    '&.Mui-checked': {
                                                        color: '#1D59F9   ',
                                                    },
                                                }}
                                            />} label="Active" />
                                            <FormControlLabel value="Once" control={<Radio
                                                sx={{
                                                    color: '#1D59F9',
                                                    '&.Mui-checked': {
                                                        color: '#1D59F9   ',
                                                    },
                                                }}
                                            />} label="One" />
                                            <FormControlLabel value="Monthly" control={<Radio
                                                sx={{
                                                    color: '#1D59F9',
                                                    '&.Mui-checked': {
                                                        color: '#1D59F9   ',
                                                    },
                                                }}
                                            />} label="Monthly" />
                                        </RadioGroup>
                                    </FormControl>

                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className=" table-scroll white-border p-3 rounded-4 mt-4" >
                    <div style={{ borderBottom: "1px solid #E4E5E7" }}>

                        <h6 className="font-14" >Device list</h6>
                    </div>
                    <div>
                        {
                            isLoading ?
                                <div className="row justify-content-center not-active-Campaigns rounded-3 text-center mt-3">
                                    <div className="col-12">
                                        <div className="Campaigns p-4 rounded-3">
                                            <Spinner color='dark' />                                                            </div>
                                    </div>
                                </div>
                                :
                                businessDevices?.devices?.length < 1 ?
                                    <div className="row justify-content-center not-active-Campaigns rounded-3 text-center mt-3">
                                        <div className="col-12">
                                            <div className="Campaigns p-4 rounded-3">
                                                <h5 className='fw-bold'>No Assigned Devices</h5>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    businessDevices?.devices?.map((index) => {
                                        return (

                                            <div className="d-flex flex-wrap device-card-data justify-content-between p-2 bg-white rounded-4 mt-2" >
                                                <div className="inter d-flex align-items-center" style={{ whiteSpace: 'nowrap' }}>
                                                    {/* <Checkbox checked={checked[0]} onChange={handleChange2} /> */}
                                                    <div className="px-3">

                                                        <Input type="checkbox"
                                                            style={{ transform: 'scale(1.3)', borderColor: '#C8C3C9', borderWidth: '1.5' }} />
                                                    </div>

                                                    <div>

                                                        {/* <h3 className="font-20 fw-semibold" style={{ color: 'rgba(74, 74, 74, 1)' }}>{index.device_model}</h3> */}
                                                        <p className="font-18 mb-2" style={{ color: 'rgba(83, 83, 83, 1)' }}>{index.serial_number}</p>
                                                        <h5 className="font-10" style={{ color: 'rgba(83, 83, 83, 1)' }}>Added {index.created_at}</h5>
                                                        {index?.is_default != null && <h5 className="font-10" style={{ color: 'rgba(83, 83, 83, 1)' }}>Default Device</h5>}

                                                    </div>
                                                </div>
                                                <div className="d-flex flex-column gap-1 align-items-center justify-content-center">
                                                    {index.status == 'active' && <Button className='btn-fill-green px-2 py-1 rounded-5 font-15 fw-semibold text-capitalize' style={{ whiteSpace: 'nowrap', minWidth: '100px' }}>Active</Button>}

                                                    {index.is_default == null && <Button onClick={() => { handleSetDefaultDevice(index.id) }} className='btn-fill-blue px-2 py-1 rounded-5 font-14 fw-normal text-capitalize' style={{ whiteSpace: 'nowrap', minWidth: '100px' }}>Set Default</Button>}
                                                </div>

                                                <div
                                                    className="d-flex align-items-center linkSetting ps-3 my-2 py-2"
                                                    style={{ whiteSpace: 'nowrap' }}
                                                >
                                                    <div>

                                                        {/* <div className=" form-switch black-switch d-flex text-start align-items-center ps-0">
                                                            <img src={Toggle} width={22} height={22} alt="w8" />

                                                            <p className="mb-0 font-10 fw-medium  ms-2" style={{ color: 'rgba(170, 170, 170, 1)' }}>https://www.timelink.live/78/5545d55d</p>
                                                        </div> */}
                                                        <div className="d-flex align-items-end mt-2 text-start">
                                                            {/* <FaHandHoldingUsd size={28} className='grey' /> */}
                                                            <img src={link} width={22} height={22} alt="w8" />
                                                            <p className="mb-0 font-10 fw-medium  ms-2" style={{ color: 'rgba(170, 170, 170, 1)' }}>{index.purchase_type}</p>
                                                        </div>
                                                        <div className="d-flex align-items-end mt-2 text-start">
                                                            {/* <AiFillSetting size={28} className='grey' /> */}
                                                            <img src={Setting} width={22} height={22} alt="w8" />

                                                            <p className="mb-0 font-10 fw-medium  ms-2" style={{ color: 'rgba(170, 170, 170, 1)' }}>{index.version}</p>
                                                        </div>
                                                        <div className="d-flex align-items-end mt-2 text-start">
                                                            {/* <AiFillSetting size={28} className='grey' /> */}
                                                            <img src={img} width={22} height={22} alt="w8" />

                                                            <p className="mb-0 font-10 fw-medium  ms-2" style={{ color: 'rgba(170, 170, 170, 1)' }}>{index?.linked_device ? businessDevices?.squareLocations?.filter(_location=> _location.id === index?.linked_device?.location_id)[0]?.name :'Not Assigned'}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <div className="d-flex flex-column gap-1">
                                                        <Button type="button" onClick={() => { handleShowModal(index.serial_number, index.id) }} className='btn-white-borderTwo text-capitalize fw-bold rounded-5 p-2  font-14' sx={{ minWidth: '100px' }}>Report Problem</Button>
                                                        <Button type="button" onClick={() => { handlelocationModal(index.id) }} className='btn-white-borderTwo text-capitalize fw-bold rounded-5 p-2  font-14' sx={{ minWidth: '100px' }}>Link Square Location</Button>
                                                    </div>
                                                </div>
                                                <div className="deviceTableLastTD">
                                                    {index.working_status == 'faulty' &&
                                                        <Button className="btn-redTwoSideRounded fw-medium px-4 text-capitalize font-12">
                                                            Faulty
                                                        </Button>
                                                    }

                                                </div>

                                            </div>
                                        )
                                    })
                        }
                    </div>
                </div>

                <Modal size="md" show={locationModal} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Link Square Location</Modal.Title>

                    </Modal.Header>
                    <Modal.Body>Check the locations you want to connect with this device.
                        {
                            businessDevices?.squareLocations?.map((location) => {
                                return (
                                    <div>
                                        <input
                                            type="checkbox"
                                            value={location?.id}
                                            onChange={handleCheckboxChange}
                                        />
                                        <label className="ms-1">{location?.name}</label>
                                    </div>
                                )
                            })
                        }
                        <p>Selected values: {selectedValues.join(', ')}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleLinkDevices}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </ThemeProvider>
            <ToastContainer />

        </>
    );
}