/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import LoyaltyPointsHeader from '../../../components/LoyaltyPointsHeader'
import upload from '../../../assets/imges/upload.png'
import cafe from '../../../assets/imges/covercafe.png'
import termsCondation from '../../../assets/imges/termsCondation.png'

import { Link, useNavigate } from 'react-router-dom'
import { Button } from '@mui/material';
import { Modal, Spinner } from 'react-bootstrap';
import AvatarEditor from 'react-avatar-editor';
import { FiZoomIn, FiZoomOut } from 'react-icons/fi';
import { FaArrowRotateLeft, FaArrowRotateRight } from 'react-icons/fa6';
import { RxCross1 } from 'react-icons/rx'
import { GrGallery } from 'react-icons/gr'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { IoCheckmarkCircleOutline } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createBusinessCampaign, emptySumitCamgpaingState, getRecentImagesList, setSumitCamgpaingState, uploadCampaignGalleryImage } from '../../../redux/slices/userSlice'

const CustomCompaignDetail = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoading, submitCampaign, recentImagesList } = useSelector((state) => state.user)
    const [name, setName] = useState();
    const [offer, setOffer] = useState();
    const [subHeading, setSubHeading] = useState();
    const [file, setFile] = useState(undefined);
    const [selectedImage, setSelectedImage] = useState(null);
    const tabClickRef = useRef()
    console.log(file)
    const [stuff, setStuff] = useState();

    useEffect(() => {
        dispatch(getRecentImagesList());
        tabClickRef?.current?.click();

    }, [])

    const handleNext = () => {
        handleSubmit();
    }
    useEffect(() => {
        console.log(submitCampaign.get('reward_name'), 'reward name from redux ____________')
        console.log('submit campaign state in redux', submitCampaign);
        setName(submitCampaign.get('offer_name'))
        setOffer(submitCampaign.get('offer_heading'))
        setStuff(submitCampaign.get('nitty_gritty_stuff'))
        setSubHeading(submitCampaign.get('offer_subheading'))
        if (!submitCampaign.get('offer_heading')) {
            setOffer(submitCampaign.get('reward_name'))
        } else {
            setOffer(submitCampaign.get('offer_heading'))
        }
        // setFile(submitCampaign.get('image') ? submitCampaign.getAll('image') : undefined)
        // setFile(submitCampaign.get('image') ? URL.createObjectURL(submitCampaign.getAll('image')) : undefined)
    }, [])
    const [btnFlag, setBtnFlag] = useState(false)
    const [previewModal, setPreviewModal] = useState(false);

    useEffect(() => {
        if (name?.length > 0 && offer?.length > 0 && subHeading?.length > 0 && stuff?.length > 0) {
            setBtnFlag(true);
        } else {
            setBtnFlag(false);
        }
    }, [name, offer, subHeading, stuff]);
    const handlesubmit = (e) => {
        e.preventDefault();
        setPreviewModal(true)
    }

    //   ---------- image crop ----------
    // const [file, setFile] = useState(undefined);

    const [editImgModal, setEditImgModal] = useState(false);
    const [image, setImage] = useState('')
    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)
    const [cnvsImage, setCnvsImage] = useState("")
    const previewRef = useRef();
    console.log(cnvsImage)
    console.log(image)

    const handlePreview = () => {
        if (previewRef.current) {
            const cnvs = previewRef.current.getImage();
            console.log('canvas get image ', cnvs)
            cnvs.toBlob((blob) => {
                setFile(blob);

                console.log(typeof (blob), "------------blob image")
                const previousImg = URL.createObjectURL(blob);
                setCnvsImage(previousImg);
                handleUploadImageToGallery(blob);
            });
            setEditImgModal(false)
            setImage('');
            console.log(cnvsImage, "-----final")
        }
    };
    function handleUploadImageToGallery(fileSelected){
        var galleryData = new FormData();
        galleryData.append('g_image' , fileSelected);
        dispatch(uploadCampaignGalleryImage(galleryData,handleUploadImageToGalleryResponse));
        tabClickRef?.current?.click();

    }
    function handleUploadImageToGalleryResponse(data){
        setSelectedImage(data.image)
    }
    function handleSubmit() {
        console.log(file + '=========file')
        if (name?.length > 0 && offer?.length > 0 && subHeading?.length > 0 && stuff?.length > 0 && selectedImage) {
            name && dispatch(setSumitCamgpaingState({ key: 'offer_name', value: name }))
            offer && dispatch(setSumitCamgpaingState({ key: 'offer_heading', value: offer }))
            subHeading && dispatch(setSumitCamgpaingState({ key: 'offer_subheading', value: subHeading }))
            stuff && dispatch(setSumitCamgpaingState({ key: 'nitty_gritty_stuff', value: stuff }))
            selectedImage && dispatch(setSumitCamgpaingState({ key: 'offer_images', value: selectedImage }))
            // navigate("/custom/marketing/targetAudience")
            handleCampaignSubmit();

        } else {
            toast.error('oops! Fields marked with * are required!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
    function handleCampaignSubmit() {

        dispatch(createBusinessCampaign(submitCampaign, handleResponse))
    }
    function handleResponse(data) {
        dispatch(emptySumitCamgpaingState())
        navigate('/marketing/campaigns/new')
    }
    return (
        <>
            <main className='bg-white p-3' style={{ ...(isLoading ? { height: '100vh' } : { minHeight: "100vh" }) }}>
                {
                    isLoading &&
                    <div style={{ height: '100vh', width: '100vw' }} className='position-absolute left-0 top-0 bg-white d-flex justify-content-center align-items-center'>
                        <Spinner size={40} color='blue' />
                    </div>
                }

                <LoyaltyPointsHeader handleNext={handleNext} label={'Complete'} buttonActive={(name?.length > 0 && offer?.length > 0 && subHeading?.length > 0 && stuff?.length > 0 && selectedImage)} />
                <div className="container inter customise-program mt-4">
                    <div className="row justify-content-center ">
                        <div className="col-lg-6 col-md-8 col-12">

                            {/* <p className='text-uppercase font-12 text-black-50'>Step 2 of 4</p> */}
                            <h3 className='font-30 fw-bold '>Campaign details</h3>
                            <p className='font-14 mt-3'>Campaign details are primarily visible to both current and potential customers.</p>

                            <form onSubmit={handlesubmit}>
                                <input className=" focus-field p-2 mt-3 rounded-3 w-100" required value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder="Campaign name" />

                                <div className="p-3 rounded-3 mt-3" style={{ border: "1px solid rgba(0, 0, 0, 0.15" }}>
                                    <p className='font-16 mb-1'>Heading</p>
                                    <p className='font-14 my-1 text-gray'>Be descriptive, keep it short and sweet. </p>

                                    <input className="focus-field p-2 mt-3 rounded-3 w-100" required value={offer}
                                        onChange={(e) => setOffer(e.target.value)} type="text" placeholder="Store grand opening!!" maxLength={35} />
                                    <p className='font-12 text-gray mb-0 text-end fst-italic'>{offer?.length}/35 characters</p>

                                </div>
                                <div className="p-3 rounded-3 mt-3" style={{ border: "1px solid rgba(0, 0, 0, 0.15" }}>
                                    <p className='font-16 mb-1'>Body</p>
                                    <p className='font-14 my-1 text-gray'>Keep it brief and informative to enhance customer engagement with your campaign.</p>

                                    <textarea className="focus-field p-2 mt-3 rounded-3 font-14 w-100 " required value={subHeading}
                                        onChange={(e) => setSubHeading(e.target.value)} type="text" placeholder="Store grand opening!!" maxLength={120} ></textarea>
                                    <p className='font-12 text-gray mb-0 text-end fst-italic'>{subHeading?.length}/120 characters</p>

                                </div>
                                <div className="p-3 rounded-3 mt-3" style={{ border: "1px solid rgba(0, 0, 0, 0.15" }}>
                                    <p className='font-16 mb-1'>Terms & Conditions</p>
                                    <p className='font-14 my-1 text-gray'>Include key stuff, e.g., valid only at XX offer, limited time.</p>
                                    <textarea className="focus-field p-2 mt-3 rounded-3 font-14 w-100 " required value={stuff}
                                        onChange={(e) => setStuff(e.target.value)} type="text" placeholder="Store grand opening!!" maxLength={300} />
                                    <p className='font-12 text-gray mb-0 text-end fst-italic'>{stuff?.length}/300 characters</p>

                                </div>
                                <button type="submit" disabled={!btnFlag} className={`${btnFlag ? "btn-blue" : ""} mt-3 p-14 fw-bolder rounded-3 text-capitalize  border-0 `}  >Preview</button>

                            </form>
                            <div className='compaign-detail-tab rounded-5 mt-3'>
                                <div>
                                    <div className="bg-white d-flex justify-content-center rounded-5 py-4">

                                        <ul className="nav nav-pills rounded-5 d-inline-flex" id="pills-tab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link active rounded-5 fw-bold" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">New Upload</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button ref={tabClickRef} className="nav-link rounded-5 fw-bold" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Recent</button>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex={0}>
                                            <div className='compaign-detail-tab-img p-4' onClick={() => setEditImgModal(true)} style={{ borderBottomLeftRadius: "2rem", borderBottomRightRadius: "2rem" }}>
                                                <div className='p-5  text-center rounded-5 ' style={{ border: '2px dashed #E2E6EA' }}>
                                                    <p className='mb-0 text-dark-gray'>Click to browse or <br /> drag and drop your files</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab " tabIndex={0}>
                                            <div className="p-2">

                                                <div className='' style={{ borderTop: "1.5px solid #EBEFF2" }}>
                                                    <div className='row gap-5 p-2 ps-4' style={{ borderBottom: "1.5px solid #EBEFF2" }}>

                                                        {/* <div className='d-flex '>

                                                            <div>
                                                                <GrGallery className='text-blue ' />
                                                            </div>
                                                            <div className='ms-2'>
                                                                <p className='font-14 mb-0 fw-medium'>marketing-campaign-test-3.png</p>
                                                                <p className='font-12 mb-0'>Just now</p>
                                                            </div>
                                                        </div>
                                                        <div className='flex  gap-1 items-center'>
                                                            <span className='p-2 rounded-2 font-12 fw-semibold ' style={{ border: "1px solid #EBEFF2" }}>
                                                                929KB
                                                            </span>
                                                            <BsThreeDotsVertical />
                                                        </div> */}
                                                        {
                                                            recentImagesList?.map((image) => {
                                                                return (
                                                                    <div className={`col-3 p-2 border cursor-pointer ${selectedImage === image.image? 'border-primary':''} rounded-2`} onClick={()=>{setSelectedImage(image.image)}}>
                                                                        <img src={image?.url} alt="recentimage" className='w-100 h-100 rounded-2' />
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    {/* <div className='  d-flex justify-content-between align-items-center p-2' style={{ borderBottom: "1.5px solid #EBEFF2" }}>

                                                        <div className='d-flex '>

                                                            <div>
                                                                <GrGallery className='text-blue ' />
                                                            </div>
                                                            <div className='ms-2'>
                                                                <p className='font-14 mb-0 fw-medium'>marketing-campaign-test-3.png</p>
                                                                <p className='font-12 mb-0'>Just now</p>
                                                            </div>
                                                        </div>
                                                        <div className='flex  gap-1 items-center'>
                                                            <span className='p-2 rounded-2 font-12 fw-semibold ' style={{ border: "1px solid #EBEFF2" }}>
                                                                929KB
                                                            </span>
                                                            <BsThreeDotsVertical />
                                                        </div>
                                                    </div> */}
                                                    <div className='text-center p-2 pb-0'>
                                                        <button type='button' className='rounded-5 text-dark bg-transparent p-2 px-3 font-12 fw-semibold ' style={{ border: "1.5px solid #EBEFF2" }}>View all uploads</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" p-3 d-flex align-items-center" style={{ backgroundColor: "#F7F9FB  ", borderBottomLeftRadius: "2rem", borderBottomRightRadius: "2rem" }}>
                                                <IoCheckmarkCircleOutline size={18} className='me-1' />
                                                <p className='font-12 mb-0'>Last synced: 3 mins ago</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>

            </main>
            <Modal show={editImgModal} className='business_admin_main' centered onHide={() => setEditImgModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {image && (
                            <>
                                <div className="imag_crop_shadow d-flex justify-content-center ">

                                    <AvatarEditor
                                        width={250}
                                        height={120}
                                        ref={previewRef}
                                        border={50}
                                        borderRadius={14}
                                        color={[80, 76, 76, 0.6]}
                                        scale={scale}
                                        rotate={rotate}
                                        image={URL.createObjectURL(image)}
                                    />
                                </div>
                                <div className='mt-2'>
                                    <button className='btn-blue rounded-2 m-1' onClick={() => setScale(scale - 0.1)}><FiZoomOut /></button>
                                    <button className='btn-blue rounded-2 m-1' onClick={() => setScale(scale + 0.1)}><FiZoomIn /></button>

                                    <button className='btn-blue rounded-2 m-1' onClick={() => setRotate(rotate - 90)}><FaArrowRotateLeft /></button>
                                    <button className='btn-blue rounded-2 m-1' onClick={() => setRotate(rotate + 90)}><FaArrowRotateRight /></button>
                                </div>
                            </>
                        )}

                        <div className=" inputgroup1 p-3 mt-1 text-center ">

                            <label htmlFor="file" className='h-100 w-100 cursor-pointer' >
                                <div className="px-3 py-3 mb-3 ">
                                    <div className="flex justify-center">

                                        <img src={upload} width={60} alt='campain' />
                                    </div>
                                    <h6 className="font-15 font-500 inter colorblack mt-2 point">Drag &amp; drop
                                        files or <span className="colorblue text-decoration-underline">Browse</span>
                                    </h6>
                                    <p className="mb-0 mt-2 font-12 colorgray inter point">Supported
                                        formates: <br />
                                        JPEG, PNG</p>
                                </div>
                            </label>
                        </div>
                        <input type="file" id='file' onChange={(e) => setImage(e.target.files[0])} className="d-none" accept="image/*" />
                    </div>
                </Modal.Body>
                {image && <Modal.Footer>
                    <button className='btn-blue rounded-2 p-2 m-1' onClick={handlePreview}>Upload</button>

                </Modal.Footer>}
            </Modal>


            <Modal size='md' show={previewModal} className='preview-img' centered
                onHide={() => setPreviewModal(false)}>
                <Modal.Header closeButton className='pb-0 p-4' style={{ borderBottom: "none" }}>
                    <h4 className='fw-bold'>Marketing Campaign Preview</h4>
                </Modal.Header>
                <Modal.Body className='pt-0 p-4'>
                    <div>
                        <p>This preview shows how customers will see it. Note: Appearance may vary slightly based on device and settings.</p>
                        <div className="container-fluid">
                            <div className="row justify-content-center ">
                                <div className="col-9">
                                    <div className=' rounded-5 mt-3'>
                                        <div>
                                            <div className=" d-flex justify-content-center rounded-5 py-4">

                                                <ul className="nav nav-pills rounded-5 d-inline-flex" id="pills-tab" role="tablist" style={{ background: "#F7F9FB", padding: "6px" }}>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link active rounded-5 fw-bold" id="pills-front-tab" data-bs-toggle="pill" data-bs-target="#pills-front" type="button" role="tab" aria-controls="pills-front" aria-selected="true">Front</button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link rounded-5 fw-bold" id="pills-back-tab" data-bs-toggle="pill" data-bs-target="#pills-back" type="button" role="tab" aria-controls="pills-back" aria-selected="false">Back</button>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="tab-content" id="pills-tabContent">
                                                <div className="tab-pane fade show active" id="pills-front" role="tabpanel" aria-labelledby="pills-front-tab" tabIndex={0}>
                                                    <div>
                                                        <div className='p-3  text-center rounded-5 ' style={{ boxShadow: ' 0px 0px 0px 1px #00000026 inset', minHeight: "440px" }}>
                                                            <div className='d-flex justify-content-between '>
                                                                <RxCross1 style={{ visibility: "hidden" }} />
                                                                <p className='mb-0 fw-bold'>Thanks for ordering:</p>
                                                                <RxCross1 />
                                                            </div>
                                                            <img src={cnvsImage ? cnvsImage : cafe} className=" object-fit-cover rounded-4 w-75 shadow-sm mt-3 mx-auto" height={120} alt="w8" />
                                                            <div className="text-start  mt-2 mb-0">
                                                                <h6 className="font-14 mb-1 fw-bold inter">
                                                                    {name}
                                                                </h6>
                                                                <p
                                                                    className="font-14 fw-medium inter"
                                                                    style={{ color: "#5B5B5B", wordBreak: "break-word" }}
                                                                >
                                                                    {subHeading} <u role='button'
                                                                    >Terms & Conditions</u> Details will be applied to account +123456789.
                                                                </p>
                                                            </div>
                                                            <div className="container-fluid px-3 pb-3 align-self-center">
                                                                <div className="row justify-content-center">
                                                                    <div className="col-9">

                                                                        <Button
                                                                            type="button"
                                                                            className="btn-fill-blue p-2 helvetica-medium fw-medium w-100 rounded-5  text-white mx-auto font-14-69 "

                                                                        >
                                                                            Sure
                                                                        </Button>
                                                                    </div>
                                                                    <div className="mt-2  col-7 text-center">

                                                                        <Link
                                                                            className="text-decoration-underline helvetica-light" style={{ color: "#636366" }}

                                                                        >
                                                                            Maybe later
                                                                        </Link>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="pills-back" role="tabpanel" aria-labelledby="pills-back-tab" tabIndex={0}>
                                                    <div>
                                                        <div className='p-3  text-center rounded-5 ' style={{ boxShadow: ' 0px 0px 0px 1px #00000026 inset', minHeight: "440px" }}>
                                                            <div className="d-flex justify-content-between align-items-start pt-3  ">
                                                                <div>
                                                                    <h4 className='fw-bold mb-0'>Terms & Conditions</h4>

                                                                </div>

                                                                <img role='button' src={termsCondation} alt="w8" width={25} />


                                                            </div>
                                                            <div className="text-start  mt-2 mb-0">

                                                                <p
                                                                    className="font-14 fw-medium inter"
                                                                    style={{ color: "#5B5B5B", wordBreak: "break-word" }}
                                                                >
                                                                    {stuff}                                                                </p>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
            <ToastContainer />
        </>

    )
}

export default CustomCompaignDetail
