import React, { useEffect, useState } from 'react';
import { FcGoogle } from "react-icons/fc";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { Col, Container, Row } from 'reactstrap';
import logoTwo from '../../assets/imges/Logo.png';
import '../../style/businesLogin.scss';
import OTPInput from 'react-otp-input';
import { resendOtp, seTgetErrorNull, verifyOtp } from '../../redux/slices/userSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Spinner } from 'react-bootstrap';
import { useToast } from '../../components/toast/ToastContext';
import FullScreenLoader from '../../components/loaders/FullScreenLoader';
const TrialOtpVerify = () => {
  const dispatch = useDispatch();
  const { isLoading, getError } = useSelector((state) => state.user)
  const navigate = useNavigate();
  const showToast = useToast();

  const [otp, setOtp] = useState('');
  const [localLoading, setLocalLoading] = useState(false);
  useEffect(() => {
    if (getError && getError.length > 1) {
      toast.error(getError, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      dispatch(seTgetErrorNull());

    }
  }, [getError])
  const renderInput = (props, index) => {
    const isFilled = otp[index] !== undefined && otp[index] !== '';
    const className = `!w-14 h-20 bg-white border-[1.5px] font-medium  rounded-xl text-3xl focus:outline-none ${isFilled ? 'border-blue-600' : 'border-gray-300'
      } `;

    return <input {...props} className={className} />;
  };

  useEffect(() => {
    if (otp.length >= 5) {
      handleSubmit()
    }
  }, [otp])

  const handleSubmit = () => {
    if (otp.length > 4) {
      var data = {
        token: otp,
        email: localStorage.getItem('authEmail')
      };
      setLocalLoading(true)
      dispatch(verifyOtp(data, handleResponse));
    }
  }
  function handleResnedOtp() {
    var data = {
      email: localStorage.getItem('authEmail')
    };
    dispatch(resendOtp(data, handleResponse))
  }
  function handleResponse(data, isResend) {
    if (isResend) {
      showToast('Email Resent!', 'info')

    } else {
      showToast('Your email is confirmed!', 'success')
      setTimeout(() => {
        navigate('/profile/set')
      }, 2000);
    }
  }
  return (
    <>
     {
      localLoading &&
      <FullScreenLoader isLoading={isLoading} />
    }
      {
        isLoading &&
        <div style={{ height: '100vh', width: '100vw', zIndex: 100 }} className='position-absolute z-index-5 left-0 top-0 bg-white d-flex justify-content-center align-items-center'>
          <Spinner size={40} color='blue' />
        </div>
      }
      <Container fluid className=' inter' >
        <div className='min-h-screen flex flex-col' style={{ backgroundColor: '#ffffff' }}>

          <div className='flex justify-between items-center gap-2 p-2 h-fit' >
            <img src={logoTwo} alt="w8" className=' ' width="150" />
            <Link to="/auth/signup">
              <button type='button' className='px-3 p-2  border-0 rounded-lg text-blue-600'>Back to Sign up screen</button>
            </Link>
          </div>
          <Row className='justify-center align-items-center justify-content-center d-flex flex-grow-1'>
            <Col lg={6} md={12} sm={12} xs={12} className='' >
              <Container fluid >
                <Row className='justify-content-center'>
                  <Col lg={9} md={9} sm={12} xs={12} >
                    <div>
                      <div className='inter text-center'>
                        <h4 className="font-24 fw-bold text-black ">Enter confirmation code</h4>
                        <p className="font-17   text-gray-800 mb-1">We sent the code to {' '}<strong>{localStorage.getItem('authEmail')}</strong></p>


                        <p className="font-17  text-gray-800 ">Go to your inbox, copy the code and paste it below to confirm your email.</p>

                      </div>
                      <div className="flex justify-center mt-4 security-otp">
                        <OTPInput
                          value={otp}
                          onChange={setOtp}
                          numInputs={5}
                          renderSeparator={<span className="text-white mx-2"></span>}
                          renderInput={renderInput}

                        // renderInput={(props) => <input {...props} className='' />}
                        />
                      </div>
                    </div>
                    <Row className='inter mt-4 justify-center'>
                      <Col lg={6} md={6} sm={6} xs={12} className='flex justify-center'>

                        <button className='just-border bg-tranparent p-3 whitespace-nowrap text-center rounded-4 flex justify-center items-center gap-1'>
                          <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.0225 16.1285H6.40937V10.3329L3 7.77539V15.1048C3 15.671 3.45875 16.1285 4.0225 16.1285Z" fill="#4285F4" />
                            <path d="M14.5918 16.1279H16.978C17.5437 16.1279 18.0005 15.6691 18.0005 15.1048V7.77539L14.5918 10.3316" fill="#34A853" />
                            <path d="M14.5918 5.90045V10.3317L18.0005 7.77608V6.4117C18.0005 5.14733 16.5568 4.42608 15.5462 5.18483" fill="#FBBC04" />
                            <path d="M6.41016 10.3329V5.90039L10.5008 8.96977L14.592 5.90102V10.3323L10.5008 13.4016" fill="#EA4335" />
                            <path d="M3 6.41233V7.77545L6.40937 10.3317V5.90045L5.45438 5.18483C4.44187 4.42608 3 5.14733 3 6.4117" fill="#C5221F" />
                          </svg>
                          <span onClick={() => { window.open('https://mail.google.com', '_blank') }} className='fw-bold ms-2'>Open Gmail</span>
                        </button>
                      </Col>


                    </Row>
                    <p className="text-center  mt-4">
                      Didn't get an email?
                      <span className="font-17  cursor-pointer colorPrimary inter"
                        onClick={handleResnedOtp}> {isLoading ? <Spinner size='sm' color='blue' /> : 'Send it again'} </span>or <br />
                      <span className="font-17 cursor-pointer colorPrimary inter"
                        onClick={() => navigate('/auth/signup')}> Visit Help Center</span> to learn more.
                    </p>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>

        </div>
        <ToastContainer />
      </Container>
    </>
  );
}

export default TrialOtpVerify;
